@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,500i,700,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,700,900');

* {
    box-sizing: border-box;
}

html {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: Poppins;
    font-size: 66px;
    line-height: 77px;
    font-weight: 900;
}

h2 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 38px;
    line-height: 48px;
}
h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
}
h3 b {
    font-family: Poppins;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
}

h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.Tight {
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
}

.Center {
    text-align: center;
}
.Grey {
    color: #9d9d9d;
}

.DarkGrey {
    color: #737373;
}

p {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
a.InTextLink {
    color: #1c2b60;
}

/* Alternate Font Style */
.Two {
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
}
.Two.Black {
    color: #000;
}

.Two.Grey {
    color: #737373;
}

a {
    text-decoration: none;
}

hr {
    border: none;
    border-top: #eee 0.5px solid;
}

.RightBorder {
    border-right: #eee 0.5px solid;
}

.GreenOutline {
    background: white;
    color: #82bc00;
    border: 2px solid #82bc00;
}

.ElevatedCard {
    margin: 1em;
    border: 1px solid #eeeeee;
    border-radius: 4px !important;
    box-shadow: 0 4px 12px rgba(145, 145, 145, 0.25) !important;
    padding: 2em;
}

.ExtraRound {
    border-radius: 20px !important;
}

.ContentCard {
    margin-top: 3em;
    position: relative;
}

.ContentCard .BackButton {
    float: left;
    padding: 0.5em;
    min-width: 32px;
    position: absolute;
    top: 1em;
    left: 1em;
    border-radius: 16px;
}
.NoBorder,
.NoBorder .MuiTableCell-root {
    border: none;
}

.MuiFormGroup-root {
    margin: 0 0 1.5em;
}

.MuiFormControl-root .MuiFormGroup-root[role='radiogroup'] {
    margin: 0;
}

.MuiStepIcon-root .MuiStepIcon-text {
    fill: white !important;
}

.MuiFilledInput-root {
    background: none !important;
}
.MuiButton-root {
    text-transform: none !important;
}

.MuiButton-outlined {
    border-width: 1px !important;
    /*
    border-color: black !important;
    padding: 1em 1.5em !important; Commenting this out because it doesn't belong on every button */
    font-weight: bold !important;
}

/*.MuiButton-outlinedPrimary {*/
/*    padding: 1em 1.5em !important;*/
/*    border-color: #82BC00 !important;*/
/*    color: #82BC00 !important;*/
/*    border-width: 2px !important;*/
/*}*/

.MuiDialogActions-root button .MuiButton-label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
}

.MuiChip-root.wide {
    padding-left: 10px;
    padding-right: 10px;
}

.MuiChip-root.reverse {
    flex-direction: row-reverse;
}

.MuiChip-root.reverse .MuiChip-icon {
    margin-left: 0;
    margin-right: 4px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

.icon-wrapper svg {
    margin-right: 10px;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.text-white {
    color: #ffffff;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-5 {
    margin-top: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.Section {
    padding: 8em 0;
}

.SectionBlue {
    background: #1c2b60;
    color: white;
}

.SectionBlue p {
    color: white;
}

.SectionBlue a {
    color: white;
    text-decoration: underline;
}

.SectionBlue h4,
.SectionBlue h2,
.SectionBlue h3 {
    color: #e88120;
}

.SectionGray {
    background-color: #fafafa;
}

.TextOrange {
    color: #e88120;
}

.TextWhite {
    color: #ffffff;
}

.TextCenter {
    text-align: center;
}

.Flex {
    display: flex;
}

.BottomBorder {
    border-bottom: 1px solid #ebebeb;
}

iframe:not(#atlwdg-frame) {
    margin-top: 2em;
}

.underline {
    text-decoration: underline;
}

.capitalize {
    text-transform: capitalize;
}

/* SPECIFIC PRINT MEDIA QUERIES FOR SPONSOR MENU PAGE */
@media print {
    .MuiToolbar-root {
        display: none !important;
    }

    header.MuiPaper-root.MuiAppBar-root + div {
        display: none !important;
    }

    /* .sponsor-menu-page-wrapper {
        padding: 0 !important;
        min-width: 1500px;
    } */

    .MuiDrawer-paper {
        display: none !important;
    }
    .sponsor-menu-meal-slot-select {
        display: none !important;
    }
    .sponsor-menu-form {
        display: none !important;
    }
}

/* SPECIFIC PRINT MEDIA QUERIES FOR CUSTOMER SPONSOR MENU PAGE */
@media print {
    .MuiAppBar-root {
        display: none !important;
    }

    .Footer {
        display: none !important;
    }

    header.MuiPaper-root.MuiAppBar-root + div {
        display: none !important;
    }

    .sponsor-menu-page-wrapper {
        padding: 0 !important;
        min-width: 1500px;
    }

    .customer-sidebar {
        display: none !important;
    }

    .MuiDrawer-paper {
        display: none !important;
    }
    .sponsor-menu-meal-slot-select {
        display: none !important;
    }
    .sponsor-menu-form {
        display: none !important;
    }
}

/* SPECIFIC PRINT MEDIA QUERIES FOR ROUTE SHEETS PAGE */
@media print {
    .MuiAppBar-root {
        display: none !important;
    }

    .Footer {
        display: none !important;
    }

    header.MuiPaper-root.MuiAppBar-root + div {
        display: none !important;
    }

    .customer-sidebar {
        display: none !important;
    }

    .MuiDrawer-paper {
        display: none !important;
    }

    .dispatch-page-date-selector {
        display: none !important;
    }
    .dispatch-page-warehouse-selector {
        display: none !important;
    }

    .page-heading {
        display: none !important;
    }

    .route-sheet-table-comp {
        width: 100% !important;
        /* transform: scale(0.8);
        transform-origin: 0 0; */
    }

    .route-sheets-list-container {
        width: 100% !important;
        height: 100vh;
    }

    .route-sheets-list-container-div {
        height: 100vh;
    }

    .route-sheet-table-heading {
        color: black !important;
        padding: 8px !important;
    }

    .route-sheet-table-cell {
        color: black !important;
        padding: 3px !important;
    }

    @page {
        margin: 2px;
    }
}

@media print {
    /* * {
        margin: 0 !important;
        padding: 0 !important;
    } */
    #controls,
    .footer,
    .footerarea {
        display: none;
    }

    .template {
        width: auto;
        left: 0;
        top: 0;
    }
    img {
        width: 100%;
    }
    li {
        margin: 0 0 10px 20px !important;
    }

    table {
        page-break-inside: avoid;
    }
}
